
import { message } from 'ant-design-vue'
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  VNode,
  watch
} from 'vue'

import { MESSAGE } from '@/constants/message'
import { ModuleNames } from '@/constants/vuex'
import { NodeType } from '@/libs/bayes/enums/NodeType'
import { Network } from '@/libs/bayes/Network'
import { State } from '@/libs/bayes/State'
import { Variable } from '@/libs/bayes/Variable'
import { CSS, STATE_THEME } from '@/libs/theme'
import { tableScrollY } from '@/libs/utils'
import { useStore } from '@/store'
import { NetworkActionEnum } from '@/store/enums/actions/network'
import { NetworkStateEnum } from '@/store/enums/states/network'
import { vuexActions } from '@/store/util'
import { InputChangeEvent, NetworkSchema, Node, StateSchema } from '@/types'
// ROW_DATA_KEYS.KEY

enum ROW_DATA_KEYS {
  KEY = 'key',
  NUMBER_OF_STATES = 'numOfState',
  STATE_DESCRIPTION = 'stateDescription',
  STATE_ID = 'stateId',
  STATE_INDEX_IN_VARIABLE = 'stateIdxInVariable',
  STATE_NAME = 'stateName',
  VARIABLE_DESCRIPTION = 'variableDescription',
  VARIABLE_ID = 'variableId',
  VARIABLE_NAME = 'variableName',
  STATE_OBJ = 'stateObj'
}

enum TABLE_COLUMNS_TITLE {
  VARIABLE_DESCRIPTION = 'Variable Description',
  STATE_DESCRIPTION = 'State Description',
  STATE = 'State',
  VARIABLE = 'Variable'
}

const TABLE_HEIGHT_OFFSET = 51 + 54 // table header height + actions height

interface RowData {
  [ROW_DATA_KEYS.KEY]: string
  [ROW_DATA_KEYS.NUMBER_OF_STATES]: number
  [ROW_DATA_KEYS.STATE_DESCRIPTION]: string | undefined
  [ROW_DATA_KEYS.STATE_ID]: string
  [ROW_DATA_KEYS.STATE_INDEX_IN_VARIABLE]: number
  [ROW_DATA_KEYS.STATE_NAME]: string
  [ROW_DATA_KEYS.VARIABLE_DESCRIPTION]: string | undefined
  [ROW_DATA_KEYS.VARIABLE_ID]: string
  [ROW_DATA_KEYS.VARIABLE_NAME]: string
  [ROW_DATA_KEYS.STATE_OBJ]: any
}

const getTableData = (network: NetworkSchema): RowData[] | [] => {
  const { nodes } = network
  const net = new Network(network)
  const data = []
  for (const node of nodes) {
    const {
      id: variableId,
      states,
      description: variableDescription,
      name: variableName,
      type
    } = node
    // skip non CPT node
    if (type === NodeType.CPT) {
      const numOfState = states.length
      const variableObj: Variable = net.variableMap[variableId]
      for (const [idx, state] of states.entries()) {
        const { id: stateId, name: stateName, description: stateDescription } = state
        const stateObj: State = variableObj.stateMap[stateId]
        data.push({
          [ROW_DATA_KEYS.KEY]: stateId,
          [ROW_DATA_KEYS.NUMBER_OF_STATES]: numOfState,
          [ROW_DATA_KEYS.STATE_DESCRIPTION]: stateDescription,
          [ROW_DATA_KEYS.STATE_ID]: stateId,
          [ROW_DATA_KEYS.STATE_INDEX_IN_VARIABLE]: idx,
          [ROW_DATA_KEYS.STATE_NAME]: stateName,
          [ROW_DATA_KEYS.VARIABLE_DESCRIPTION]: variableDescription,
          [ROW_DATA_KEYS.VARIABLE_ID]: variableId,
          [ROW_DATA_KEYS.VARIABLE_NAME]: variableName,
          [ROW_DATA_KEYS.STATE_OBJ]: stateObj
        })
      }
    }
  }
  return data
}

const tableColumns = (onChange: any, editable: boolean, updating: boolean) => [
  {
    title: TABLE_COLUMNS_TITLE.VARIABLE,
    dataIndex: ROW_DATA_KEYS.VARIABLE_NAME,
    customRender: ({ text, record }: { text: string; record: RowData }) => {
      const obj: { children: VNode; attrs: { rowSpan?: number } } = {
        children: (
          <div class='sz-network-variable-description variable-name'>
            <span>{text}</span>
          </div>
        ),
        attrs: {}
      }
      if (record[ROW_DATA_KEYS.STATE_INDEX_IN_VARIABLE] === 0) {
        obj.attrs.rowSpan = record[ROW_DATA_KEYS.NUMBER_OF_STATES]
      } else {
        obj.attrs.rowSpan = 0
      }
      return obj
    }
  },
  {
    title: TABLE_COLUMNS_TITLE.VARIABLE_DESCRIPTION,
    dataIndex: ROW_DATA_KEYS.VARIABLE_DESCRIPTION,
    customRender: ({ text, record }: { text: string; record: RowData }) => {
      const onVariableDescriptionChange = ({ target: { value } }: InputChangeEvent) => {
        const { variableId } = record
        onChange({ value, variableId })
      }
      const obj: { children: VNode; attrs: { rowSpan?: number } } = {
        children: (
          <div>
            {editable ? (
              <a-textarea
                class='sz-network-variable-description variable-desc'
                value={text}
                onChange={onVariableDescriptionChange}
                placeholder={MESSAGE.NETWORK_VARIABLE_DESCRIPTION_PLACEHOLDER}
                disabled={updating}
              />
            ) : (
              <p class='sz-network-variable-description'>{text}</p>
            )}
          </div>
        ),
        attrs: {}
      }
      if (record[ROW_DATA_KEYS.STATE_INDEX_IN_VARIABLE] === 0) {
        obj.attrs.rowSpan = record[ROW_DATA_KEYS.NUMBER_OF_STATES]
      } else {
        obj.attrs.rowSpan = 0
      }
      return obj
    }
  },
  {
    title: TABLE_COLUMNS_TITLE.STATE,
    dataIndex: ROW_DATA_KEYS.STATE_NAME,
    // customRender: ({ text }: { text: string }) => {
    //   const obj: { children: VNode } = {
    //     children: <p class='sz-network-variable-description state-name'>{text}</p>
    //   }
    //   return obj
    // }
    customRender: ({ text, record }: { text: string; record: RowData }) => {
      const obj: { children: VNode } = {
        children: (
          <div
            class='sz-network-variable-description state-name'
            style={CSS(STATE_THEME[record.stateObj.polarity], 0)}
          >
            <span>{text}</span>
          </div>
        )
      }
      return obj
    }
  },
  {
    title: TABLE_COLUMNS_TITLE.STATE_DESCRIPTION,
    dataIndex: ROW_DATA_KEYS.STATE_DESCRIPTION,
    customRender: ({ text, record }: { text: string; record: RowData }) => {
      const onStateDescriptionChange = ({ target: { value } }: InputChangeEvent) => {
        const variableId = record[ROW_DATA_KEYS.VARIABLE_ID]
        const stateId = record[ROW_DATA_KEYS.STATE_ID]
        onChange({ value, variableId, stateId })
      }
      const obj: { children: VNode } = {
        children: (
          <div>
            {editable ? (
              <a-textarea
                class='sz-network-variable-description state-desc'
                value={text}
                onChange={onStateDescriptionChange}
                placeholder={MESSAGE.NETWORK_VARIABLE_DESCRIPTION_PLACEHOLDER}
                disabled={updating}
              />
            ) : (
              <p class='sz-network-variable-description'>{text}</p>
            )}
          </div>
        )
      }
      return obj
    }
  }
]

const DEFAULT_EDITABLE = false
const DEFAULT_UPDATING = false

export default defineComponent({
  components: {},
  props: {
    workspaceId: { type: String, required: true }
  },
  setup() {
    const store = useStore()
    const currentNetwork = computed(
      () => store.state[ModuleNames.NETWORK][NetworkStateEnum.CURRENT_NETWORK]
    )
    const data = computed(() => getTableData(currentNetwork.value as NetworkSchema))
    const containerEl = ref(null)

    const onChange = ({
      value,
      variableId,
      stateId
    }: {
      value: string | undefined
      variableId: string
      stateId: string | undefined
    }) => {
      if (stateId) {
        const idx = tableConfig.data.findIndex((data) => data[ROW_DATA_KEYS.STATE_ID] === stateId)
        if (idx !== -1) {
          const rowData = { ...tableConfig.data[idx], [ROW_DATA_KEYS.STATE_DESCRIPTION]: value }
          tableConfig.data[idx] = rowData
          return
        }
        throw new Error(`Unexpected state id ${stateId}`)
      } else {
        let updated = false
        tableConfig.data.forEach((data, idx) => {
          if (data.variableId === variableId) {
            const rowData = { ...data, [ROW_DATA_KEYS.VARIABLE_DESCRIPTION]: value }
            tableConfig.data[idx] = rowData
            updated = true
          }
        })
        if (!updated) {
          throw new Error(`Unexpected variable id ${variableId}`)
        }
      }
    }

    const tableConfig = reactive({
      columns: tableColumns(onChange, DEFAULT_EDITABLE, DEFAULT_EDITABLE),
      data: [...data.value],
      editable: DEFAULT_EDITABLE,
      scrollY: tableScrollY(containerEl.value, TABLE_HEIGHT_OFFSET),
      updating: DEFAULT_UPDATING
    })

    watch(
      () => tableConfig.editable,
      (newVal) => {
        tableConfig.columns = tableColumns(onChange, newVal, tableConfig.updating)
      }
    )
    watch(
      () => tableConfig.updating,
      (newVal) => {
        tableConfig.columns = tableColumns(onChange, tableConfig.editable, newVal)
      }
    )

    /**
     * Listen on click 'Edit' btn
     */
    const onEdit = () => {
      tableConfig.editable = true
    }

    /**
     * Listen on click 'Update' btn
     */
    const onUpdate = async () => {
      if (currentNetwork.value !== null) {
        tableConfig.updating = true
        try {
          const nodes = currentNetwork.value.nodes
          const variables: {
            [key: string]: {
              description: string | undefined
              states: { [key: string]: string | undefined }
            }
          } = {}
          for (const rowData of tableConfig.data) {
            const variableId = rowData[ROW_DATA_KEYS.VARIABLE_ID]
            const stateId = rowData[ROW_DATA_KEYS.STATE_ID]
            const variableDescription = rowData[ROW_DATA_KEYS.VARIABLE_DESCRIPTION]
            const stateDescription = rowData[ROW_DATA_KEYS.STATE_DESCRIPTION]
            if (Object.prototype.hasOwnProperty.call(variables, variableId)) {
              variables[variableId].states[stateId] = stateDescription
            } else {
              variables[variableId] = {
                description: variableDescription,
                states: {
                  [stateId]: stateDescription
                }
              }
            }
          }

          const newNodes = nodes.map((node: Node) => {
            const { id: variableId, states } = node
            node.description = variables[variableId]?.description
            node.states = states.map((state: StateSchema) => {
              const { id: stateId } = state
              return { ...state, description: variables[variableId].states[stateId] }
            })
            return node
          })
          const network = { ...currentNetwork.value, nodes: newNodes }
          await store.dispatch(vuexActions(ModuleNames.NETWORK, NetworkActionEnum.UPDATE_NETWORK), {
            id: network.id,
            network
          })
        } catch (err) {
          tableConfig.editable = false
          tableConfig.updating = false
          onCancel()
          message.error(MESSAGE.NETWORK_VARIABLE_DESCRIPTION_UPDATE_FAIL)
          throw err
        }
        tableConfig.editable = false
        tableConfig.updating = false
        message.success(MESSAGE.NETWORK_VARIABLE_DESCRIPTION_UPDATE_SUCCESS)
        return
      }
      throw new Error('Cannot find nodes.')
    }

    /**
     * Listen on click 'Cancel' btn
     */
    const onCancel = () => {
      tableConfig.data = [...data.value]
      tableConfig.editable = false
    }

    const onResize = () => {
      tableConfig.scrollY = tableScrollY(containerEl.value, TABLE_HEIGHT_OFFSET)
    }

    /**
     * Vue life cycle
     */
    onBeforeMount(() => {
      window.addEventListener('resize', onResize)
    })

    onMounted(() => {
      tableConfig.scrollY = tableScrollY(containerEl.value, TABLE_HEIGHT_OFFSET)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize)
    })

    return {
      ROW_DATA_KEYS,
      containerEl,
      onCancel,
      onEdit,
      onUpdate,
      tableConfig
    }
  }
})
