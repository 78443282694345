<template>
  <VariableEditTable :workspace-id="workspaceId" />
</template>

<script lang="ts">
import { message } from 'ant-design-vue'
import { computed, defineComponent } from 'vue'
import { NavigationGuard, NavigationGuardWithThis, useRoute } from 'vue-router'

import VariableEditTable from '@/components/network/VariableEditTable.vue'
import { MESSAGE } from '@/constants/message'
import { PATH } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { useStore } from '@/store'
import { NetworkActionEnum } from '@/store/enums/actions/network'
import { WorkspaceActionEnum } from '@/store/enums/actions/workspace'
import { vuexActions } from '@/store/util'
import { NetworkList } from '@/types'

const loadPage: NavigationGuardWithThis<undefined> | NavigationGuard = async (to, from, next) => {
  const store = useStore()
  const { workspaceId } = to.params

  if (Array.isArray(workspaceId) || !workspaceId) {
    message.error({
      content: MESSAGE.UNEXPECTED_WORKSPACE_ID(workspaceId),
      duration: 3
    })
    setTimeout(() => {
      next(PATH.HOME)
    }, 3000)
  } else {
    await store.dispatch(
      vuexActions(ModuleNames.WORKSPACE, WorkspaceActionEnum.GET_WORKSPACE),
      workspaceId
    )
    const networks = ((await store.dispatch(
      vuexActions(ModuleNames.NETWORK, NetworkActionEnum.GET_NETWORKS),
      workspaceId
    )) as NetworkList).content
    if (networks.length === 0) {
      message.error({
        content: MESSAGE.NETWORK_NOT_SET,
        duration: 3
      })
      next(false)
      return
    }
    next()
  }
}

export default defineComponent({
  components: {
    VariableEditTable
  },
  beforeRouteEnter: loadPage as NavigationGuard,
  beforeRouteUpdate: loadPage as NavigationGuardWithThis<undefined>,
  setup() {
    const route = useRoute()
    const workspaceId = computed(() => {
      if (typeof route.params.workspaceId === 'string') {
        return route.params.workspaceId
      }
      return null
    })
    return {
      workspaceId
    }
  }
})
</script>
