import { render } from "./NetworkVariablesView.vue?vue&type=template&id=f20e5d30"
import script from "./NetworkVariablesView.vue?vue&type=script&lang=ts"
export * from "./NetworkVariablesView.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "f20e5d30"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f20e5d30', script)) {
    api.reload('f20e5d30', script)
  }
  
  module.hot.accept("./NetworkVariablesView.vue?vue&type=template&id=f20e5d30", () => {
    api.rerender('f20e5d30', render)
  })

}

script.__file = "src/views/NetworkVariablesView.vue"

export default script